var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.capacity", false, "Capacidad"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.capacity',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_capacity'),expression:"'mesh.add_capacity'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.capacity',
            false,
            'Capacidad'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-capacity-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.capacities,"fields":_vm.capacities_fields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(row.item.description)}})]}},{key:"cell(faculties)",fn:function(row){return [(row.item.is_global)?_c('div',{staticClass:"campus-container"},[[_c('div',[_vm._v(" Todas las "+_vm._s(_vm.$getVisibleNames("mesh.faculty", true, "Facultades"))+" han sido seleccionadas. ")])]],2):[_c('div',{staticClass:"campus-container"},_vm._l((_vm.getFacultiesList(row.item.faculties)),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_capacity'),expression:"'mesh.change_capacity'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.capacity',
            false,
            'Capacidad'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-capacity-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_capacity'),expression:"'mesh.delete_capacity'"}],attrs:{"click_button":() => _vm.askForDeleteCapacity(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.capacity',
            false,
            'Capacidad'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-capacity-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.capacity',
            false,
            'Capacidad'
          )}`,"size":"lg","hide-footer":""}},[_c('CapacityForm',{attrs:{"Capacity":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedCapacity}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-capacity-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames('mesh.capacity', false, 'Capacidad')}`,"size":"lg"}},[_c('CapacityForm',{on:{"created":_vm.slotCreatedCapacity}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }